const sendSingleDeviceNotification = (token) => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append(
    "Authorization",
    "key=AAAARo55xCY:APA91bFH65DdLP0ZwBMiDxNob4SVoJIKPt5F41heLeYINuv2B4nlAGczCwRYln1he-23SH8Bemk9Rl4a8v-7vhAabdXIjgCqo78E65Q8hb8xru9Nrnzf3enwODLUnnAb-cAhpQNPaOaX"
  );

  var raw = JSON.stringify({
    data: {},
    notification: {
      body: "You received a new message, Check it now! 💌",
      title: "DLTM",
      sound: "default",
    },

    to: token,
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  fetch("https://fcm.googleapis.com/fcm/send", requestOptions)
    .then((response) => response.text())
    .then((result) => console.log(result))
    .catch((error) => console.log("error", error));
};

export default {
  sendSingleDeviceNotification,
};
