import React, { Component } from "react";

import styles from "./Home.module.css";

import header from "../assets/header.jpg";
import left from "../assets/left.png";
import right from "../assets/right.png";
import footer from "../assets/footer.png";
import logo from "../assets/logo.png";
import appstore from "../assets/appstore.png";
import playstore from "../assets/playstore.png";

export default class Home extends Component {
  componentDidMount = () => {
    document.title = "DLTM";
  };

  render() {
    return (
      <div className={styles.body}>
        <img src={header} className={styles.header} />

        {/* APPSTORE & PLAYSTORE */}
        <p className={styles.title}>DLTM</p>
        <div className={styles.stores}>
          <img
            src={appstore}
            className={styles.appstore}
            onClick={() =>
              window.open(
                "https://apps.apple.com/us/app/dltm-anonymous-q-a/id1632637392",
                "_blank"
              )
            }
          />
          <img
            src={playstore}
            className={styles.playstore}
            onClick={() =>
              window.open(
                "https://play.google.com/store/apps/details?id=com.dltm.ws",
                "_blank"
              )
            }
          />
        </div>
        {/* FIRST PART */}
        <div className={styles.titleBox}>
          <img src={left} className={styles.left} />
          <img src={right} className={styles.right} />
        </div>
        <p className={styles.title}>What's DLTM?</p>
        <p className={styles.text}>
          Don't Lie To Me is a fun safe app that takes on anonymity. You are
          able to ask questions anonymously and express your deepmost sincere
          feelings and emotions without the peer pressure or judgement from
          friends and close ones.
        </p>

        {/* SECOND PART */}
        <h1 className={styles.title2}>How it Works</h1>
        <p className={styles.text}>
          First you share your DLTM link on social media story or timeline
          inviting your friends/close ones to reply to your story anonymously.
          You wait for your responses, which appear an inbox in the DLTM app.
          You can then post the messages to your social media story with a
          response.
        </p>

        {/* FOOTER */}
        <div className={styles.footerBox}>
          <img src={footer} className={styles.footer} />
          <img
            src={appstore}
            className={styles.appstoreFooter}
            onClick={() =>
              window.open(
                "https://apps.apple.com/us/app/dltm-anonymous-q-a/id1632637392",
                "_blank"
              )
            }
          />
          <img
            src={playstore}
            className={styles.playstoreFooter}
            onClick={() =>
              window.open(
                "https://play.google.com/store/apps/details?id=com.dltm.ws",
                "_blank"
              )
            }
          />
          <div className={styles.box}>
            <img src={logo} className={styles.logo} />
            <div className={styles.links}>
              <a href="http://appsandgamesinc.com/privacypolicy.html">
                Privacy Policy
              </a>
              <br />
              <br />
              <a href="http://appsandgamesinc.com/terms.html">
                Terms of Services
              </a>
              <br />
              <br />
            </div>
          </div>
          <p className={styles.copyright}>
            All Rights Reserved. Copyright © 2022
          </p>
        </div>
      </div>
    );
  }
}
