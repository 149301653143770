import React, { Component } from "react";
import { Link } from "react-router-dom";

import Lottie from "react-lottie";
import pageLoading from "../lottie/lf20_zbwv4euo";

import { db } from "../firebase-config";
import {
  collection,
  query,
  where,
  onSnapshot,
  getDocs,
  doc,
  setDoc,
  addDoc,
  serverTimestamp,
  Timestamp,
} from "firebase/firestore";

import styles from "./UserPage.module.css";
import letter from "../assets/letter.png";
import Background from "../assets/header_3.png";

import * as info from "react-device-detect";
import NotificationService from "../NotificationService";

export default class UserPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {},
      sent: false,
      counter: 4,
      loading: false,
      pageLoading: true,
      defaultOptions: {
        loop: true,
        autoplay: true,
        animationData: pageLoading,
      },
      emptyMsg: false,
    };
  }

  getUser = () => {
    const web_id = window.location.pathname.substring(1);
    try {
      const usersCollection = collection(db, "users");
      const q = query(usersCollection, where("web_id", "==", web_id));
      onSnapshot(q, async (snapshot) => {
        snapshot.docs.map(async (doc) => {
          let newUser = await doc.data();
          this.setState({ user: newUser });
        });
      });
    } catch (error) {
      // console.log(error);
    }
  };

  componentDidMount() {
    this.getUser();
    setTimeout(() => this.setState({ pageLoading: false }), 2000);
  }

  sendMessage = async () => {
    const messagesCollRef = collection(db, "messages");

    if (this.state.message != "" && this.state.message != undefined) {
      try {
        this.setState({ loading: true });
        const docRef = doc(messagesCollRef);

        await setDoc(docRef, {
          message_content: this.state.message,
          message_title: this.state.user.user_header,
          user_id: this.state.user.user_id,
          sent_at: new serverTimestamp(),
          sent_at_ms: Date.now(),
          read: false,
          message_id: docRef.id,
          informations: {
            browser_name: info.browserName,
            mobile_model: info.mobileModel,
            os_name: info.osName,
          },
        });
        this.setState({
          message: "",
          sent: true,
          counter: this.state.counter - 1,
          loading: false,
        });
      } catch (error) {
        //console.log(error);
      }

      // SEND NOTIFICATION
      if (this.state.user.notification_token != undefined) {
        this.sendNotification();
      }
    } else {
      this.setState({ emptyMsg: true, sent: false });
    }
  };

  //-----SEND NOTIFICATION----
  sendNotification = async () => {
    await NotificationService.sendSingleDeviceNotification(
      this.state.user.notification_token
    );
  };

  render() {
    return (
      <div className={styles.body}>
        {this.state.pageLoading && (
          <div className={styles.lottie}>
            <Lottie
              options={this.state.defaultOptions}
              height={250}
              width={250}
            />
          </div>
        )}

        {!this.state.pageLoading && !this.state.user.user_id && (
          <p className={styles.notFound}>Could not find user!</p>
        )}

        {!this.state.pageLoading && this.state.user.user_id && (
          <div>
            <div className={styles.box}>
              <div
                style={{ backgroundImage: `url(${Background})` }}
                className={styles.header}
              >
                <img src={letter} className={styles.image} />@
                {this.state.user.user_name} <br />
                {this.state.user.user_header}
              </div>

              <textarea
                rows={7}
                className={styles.textarea}
                placeholder="Send me an anonymous message..."
                onChange={(e) => {
                  this.setState({
                    message: e.target.value,
                    sent: false,
                    emptyMsg: false,
                  });
                }}
                value={this.state.message}
              />

              <div className={styles.boxMsg}>
                {this.state.sent && (
                  <span className={styles.msgTxt}>Message sent</span>
                )}
                {this.state.emptyMsg && (
                  <span className={styles.invalidMsg}>Invalid message</span>
                )}
              </div>

              <div className={styles.boxBtn}>
                {!this.state.loading && this.state.counter != 0 ? (
                  <button
                    className={styles.sendBtn}
                    onClick={() => {
                      this.sendMessage();
                    }}
                  >
                    Send
                  </button>
                ) : this.state.counter != 0 ? (
                  <button className={styles.sendBtn}>
                    <Lottie
                      options={this.state.defaultOptions}
                      height={50}
                      width={50}
                    />
                  </button>
                ) : (
                  <div></div>
                )}
              </div>
            </div>

            <button
              className={styles.getAppBtn}
              onClick={() => window.location.replace("/")}
            >
              <span className={styles.textBtn}>Get DLTM app!</span>
            </button>
          </div>
        )}
      </div>
    );
  }
}
