import React, { Component } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import UserPage from "./pages/UserPage";

import Home from "./pages/Home";
export default class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <div>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/:userName" element={<UserPage />} />
          </Routes>
        </div>
      </BrowserRouter>
    );
  }
}
