import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import "firebase/analytics";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  // apiKey: "AIzaSyA3og5boqExgAj24WhQtrBHYm_Q7jbpFs4",
  // authDomain: "nglproject-c04e1.firebaseapp.com",
  // projectId: "nglproject-c04e1",
  // storageBucket: "nglproject-c04e1.appspot.com",
  // messagingSenderId: "2947608090",
  // appId: "1:2947608090:web:5e09af77eaef45a7776ba3",
  // measurementId: "G-H9ZYJGJFWK",
  apiKey: "AIzaSyBGoRWdq79DycPbgen5U6FyfMbpbSh3MlE",
  authDomain: "dltm-f8195.firebaseapp.com",
  projectId: "dltm-f8195",
  storageBucket: "dltm-f8195.appspot.com",
  messagingSenderId: "303038055462",
  appId: "1:303038055462:web:7f7cf7199212596917d2e7",
  measurementId: "G-X42KY9DYWT",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const db = getFirestore(app);
